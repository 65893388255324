<template>
  <notifications class="flik-notif">
    <template #body="props">
      <div
        class="vue-notification-template flik-notif__content"
        :class="props.item.type"
        @click="props.close"
      >
        <span class="notification-title">
          <IconsCheckCircle
            v-if="props.item.type === 'success'"
            class="text-green-700"
          />
          <IconsXCircle
            v-else-if="props.item.type === 'error'"
            class="text-red-700"
          />
          <IconsInfoCircle
            v-else-if="props.item.type === 'info'"
            class="text-blue-700 w-4 h-4"
          />
          {{ props.item.title }}
        </span>
        <p class="notification-content">
          {{ props.item.text }}
        </p>
      </div>
    </template>
  </notifications>
</template>
