<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="x-circle">
      <path
        id="Vector"
        d="M9 2.25C7.66498 2.25 6.35994 2.64588 5.2499 3.38758C4.13987 4.12928 3.27471 5.18348 2.76382 6.41689C2.25292 7.65029 2.11925 9.00749 2.3797 10.3169C2.64015 11.6262 3.28303 12.829 4.22703 13.773C5.17104 14.717 6.37377 15.3598 7.68314 15.6203C8.99251 15.8807 10.3497 15.7471 11.5831 15.2362C12.8165 14.7253 13.8707 13.8601 14.6124 12.7501C15.3541 11.6401 15.75 10.335 15.75 9C15.748 7.21039 15.0362 5.49464 13.7708 4.2292C12.5054 2.96375 10.7896 2.25197 9 2.25ZM11.5022 10.5478C11.5667 10.61 11.6181 10.6845 11.6535 10.7669C11.6889 10.8492 11.7075 10.9378 11.7083 11.0274C11.7091 11.1171 11.692 11.2059 11.658 11.2889C11.6241 11.3718 11.574 11.4472 11.5106 11.5106C11.4472 11.574 11.3719 11.6241 11.2889 11.658C11.2059 11.692 11.1171 11.709 11.0274 11.7083C10.9378 11.7075 10.8492 11.6889 10.7669 11.6535C10.6845 11.6181 10.61 11.5667 10.5478 11.5022L9 9.95445L7.45223 11.5022C7.32492 11.6252 7.15441 11.6932 6.97743 11.6917C6.80045 11.6901 6.63115 11.6192 6.506 11.494C6.38085 11.3688 6.30986 11.1996 6.30832 11.0226C6.30678 10.8456 6.37482 10.6751 6.49778 10.5478L8.04555 9L6.49778 7.45222C6.37482 7.32492 6.30678 7.15441 6.30832 6.97743C6.30986 6.80045 6.38085 6.63115 6.506 6.506C6.63115 6.38085 6.80045 6.30986 6.97743 6.30832C7.15441 6.30678 7.32492 6.37482 7.45223 6.49777L9 8.04555L10.5478 6.49777C10.6751 6.37482 10.8456 6.30678 11.0226 6.30832C11.1996 6.30986 11.3689 6.38085 11.494 6.506C11.6192 6.63115 11.6901 6.80045 11.6917 6.97743C11.6932 7.15441 11.6252 7.32492 11.5022 7.45222L9.95445 9L11.5022 10.5478Z"
        fill="currentColor"
      />
    </g>
  </svg>
</template>
